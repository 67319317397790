<template>
  <div>
    <div class="tt" style="background: #fff">
      <div class="t">
        <img src="../../assets/img/huiyuanzhongxin/logo1.png" alt="" />
      </div>
    </div>
    <div class="sidebar">
      <!-- 左侧二级菜单栏的组件封装 -->

      <el-menu
        class="sidebar-el-menu"
        :default-active="toIndex()"
        background-color="white"
        text-color="#7a8297"
        active-text-color="#2d8cf0"
        router
      >
        <el-submenu index="1">
          <template slot="title">
            <span>{{ test }}</span>
          </template>
          <template v-for="item in items">
            <el-menu-item :index="item.index" :key="item.index">
              <i :class="item.icon"></i>
              <span slot="title">{{ item.title }}</span>
            </el-menu-item>
          </template>
        </el-submenu>
      </el-menu>
    </div>
  </div>
</template>

<script>
export default {
  props: ["items"],
  data() {
    return {
      test: "",
    };
  },
  created() {
    if (this.$route.path.split("/")[2].substring(0, 5) == "test1") {
      this.test = "工作台";
    } else if (this.$route.path.split("/")[2].substring(0, 5) == "test2") {
      this.test = "创新评估";
    }else if (this.$route.path.split("/")[2].substring(0, 2) == "pg") {
      this.test = "创新评估";
    } else if (this.$route.path.split("/")[2].substring(0, 5) == "test3") {
      this.test = "找技术";
    } else if (this.$route.path.split("/")[2].substring(0, 5) == "test4") {
      this.test = "找需求";
    } else if (this.$route.path.split("/")[2].substring(0, 5) == "test5") {
      this.test = "找政策";
    } else if (this.$route.path.split("/")[2].substring(0, 5) == "test6") {
      this.test = "找服务";
    } else if (this.$route.path.split("/")[2].substring(0, 5) == "test7") {
      this.test = "找人才";
    } else if (this.$route.path.split("/")[2].substring(0, 5) == "test8") {
      this.test = "科创活动";
    }
  },
  methods: {
    // 根据路径绑定到对应的二级菜单，防止页面刷新重新跳回第一个
    toIndex() {
      // console.log(this.$route.path.split("/")[2]);
      return this.$route.path.split("/")[2];
    },
  },
};
</script>

<style scoped lang="less">
.tt {
  // height: 4rem;
  // width: 13rem;
  height: 60px;
  width: 234px;
  background: #fff;
  margin-right: 0;
}
.t {
  // width: 10rem;
  // height: 4rem;
  width: 169px;
  height: 54px;
  margin-left: 25px;
}
.text {
  margin-top: 10px;
}
/* 左侧菜单栏定位和位置大小设定 */
.sidebar {
  display: block;
  position: absolute;
  left: 88px;
  top: 102px;
  bottom: 0;
  /* overflow-y: scroll; */
  // padding-top: 6rem;
  // padding-top: 102px;
  box-sizing: border-box;
}
.sidebar::-webkit-scrollbar {
  width: 0;
}
.sidebar-el-menu {
  // width: 13rem;
  width: 234px;
  box-sizing: border-box;
}
.sidebar > ul {
  height: 100%;
}

/* 左侧二级菜单项的样式 */
.el-menu-item {
  height: 100% !important;
  font-size: 16px !important;
  padding-left: 15px !important;
  color: #000000 !important;
}

/* 左侧二级菜单选中时的样式 */
.el-menu-item.is-active {
  color: #5184e0 !important;
  background: #c9d9f5 !important;
  border-right: 6px solid #5184e0;
}

.el-menu-item,
/deep/.el-submenu__title {
  height: 45px !important;
  line-height: 45px !important;
}
.is-active /deep/.el-submenu__title {
  padding-left: 15px !important;
  font-size: 18px !important;
  color: #5184e0 !important;
}
</style>