<template>
<div>
    
    <!-- 一级菜单下面所拥有的二级菜单 -->
    <el-aside>
          <SideMenu :items='items'></SideMenu>
    </el-aside>

    <!-- 以及二级菜单所对应的页面 -->
    <el-main>
        <router-view></router-view>
    </el-main>

</div>
</template>

<script>
 
import SideMenu from '@/components/common/SideMenu';
export default {
    components:{
        SideMenu
    },
    data(){
        return {
            items: [
            {  icon: 'el-icon-document-add',
                index: 'test1-1',
                title: '我发起的需求'
            },
            {
                 icon: 'el-icon-document-checked',
                index: 'test1-2',
                title: '我参与的需求'
            },
            {
                 icon: 'el-icon-s-promotion',
                index: 'test1-3',
                title: '我发布的成果'
            },
            {
                 icon: 'el-icon-monitor',
                index: 'test1-4',
                title: '我发布的招聘'
            },
       { icon: "el-icon-s-comment", index: "test1-5", title: "咨询详情" },

        ],
        }
    }
}
</script>

<style scoped>
 </style>